class Editions {
  static listener() {
    const button = document.querySelector('#edition-toggle')
    button.addEventListener('click', ev => {
      document.querySelector('#edition-toggle + ul').classList.toggle('open')
      button.classList.toggle('open')
    })
  }
}

export default Editions