class MarketsToggle {

  constructor() {
    this.list = document.querySelector('.markets__list')
    this.button = document.querySelector('.markets__toggle')
    this.listen()
  }

  listen() {
    this.button.addEventListener('click', () => {
      if (this.button.dataset.open == "false") {
        this.button.dataset.open = "true"
        this.list.style.maxHeight = (this.list.scrollHeight + 30) + "px"
      } else {
        this.button.dataset.open = "false"
        this.list.removeAttribute('style')
      }
      this.toggleText()
    })
  }

  toggleText() {
    const toggleText = this.button.firstElementChild.getAttribute('data-toggle-text')
    this.button.firstElementChild.setAttribute('data-toggle-text', this.button.firstElementChild.innerHTML)
    this.button.firstElementChild.innerHTML = toggleText
  }

}

export default MarketsToggle