class Jobs {

  constructor() {
    // character counter
    this.maxCharacterListener(50)
    // Preview logo on upload
    this.previewLogo()
    // Preview listing using template
    this.previewListing()
    // Fetch laravel-job (send email, create entry in job collection)
    this.radioListener()
    this.stepListener()
  }

  maxCharacterListener(maxChars) {
    const el = document.querySelector('#job-title')
    el.setAttribute('maxlength', maxChars)
    const maxCharEl = document.querySelector('.job__max-characters')
    const counterEl = document.querySelector('#character-counter')
    const maxEl = document.querySelectorAll('.character-max')
    maxEl.forEach(el => el.innerHTML = maxChars.toString())
    el.addEventListener('keyup', (ev) => {
      if (el.value.length === maxChars) {
        ev.preventDefault()
        maxCharEl.classList.add('job__max-characters--danger')
        counterEl.innerHTML = maxChars.toString()
      } else {
        maxCharEl.classList.remove('job__max-characters--danger')
        counterEl.innerHTML = el.value.length
      }
    })
  }

  previewLogo() {
    document.querySelector('#job-logo').addEventListener('change', (ev) => {
      const input = ev.target
      const logoPreview = document.querySelector('#job-logo-preview')
      if (input.files && input.files[0]) {
        let reader = new FileReader()
        reader.onload = e => {
          logoPreview.src = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    })
  }

  previewListing() {
    document.querySelector('#job-preview').addEventListener('click', ev => {
      const template = document.querySelector('#job-preview-template')
      const clone = template.content.cloneNode(true)
      let dialog = clone.querySelector('.jobs-dialog')
      dialog.querySelector('.job__title').innerHTML = document.querySelector('#job-title').value
      dialog.querySelector('.job__location').innerHTML = document.querySelector('#job-location').value
      dialog.querySelector('.job__logo img').src = document.querySelector('#job-logo-preview').src
      dialog.querySelector('.job__closing-date .job__data').innerHTML = document.querySelector('#job-closing').value
      dialog.querySelector('.job__employment-form .job__data').innerHTML = document.querySelector('#job-type').selectedOptions[0].innerHTML
      dialog.querySelector('.job__employer .job__data').innerHTML = document.querySelector('#job-employer').value
      document.body.appendChild(dialog)
      dialog.addEventListener('click', ev => {
        if (ev.target.classList.contains('jobs-dialog')) {
          dialog.remove()
        }
      })
      dialog.querySelector('#job-dialog-close').addEventListener('click', () => {
        dialog.remove()
      })
      document.querySelector('#job-dialog-submit').addEventListener('click', (ev) => {
        ev.preventDefault()
        this.submitJob()
      })
    })
  }

  submitJob() {
    // POST fetch to backend, to start sending email, and create entry
    fetch('/!/DynamicToken/refresh').then(res => res.json().then(data => {
      document.querySelector('.jobs-form__form [name="_token"]').value = data.csrf_token
      let formData = new FormData(document.querySelector('.jobs-form__form'))
      formData = this.appendFormObject(formData)
      let options = {
        method: 'POST',
        body: formData
      }
      fetch('/api/jobs/create', options).then(res => res.json().then(data => {
        // Remove dialog and form, and display "Job listing sent, and will be published as soon as possible"
        document.querySelector('#job-form').remove()
        document.querySelector('.jobs-dialog').remove()
        document.querySelector('.jobs-form').innerHTML = "Job listing submitted, and will be published soon, after a manual review"
      }))
    }))
  }

  radioListener() {
    document.addEventListener('click', (ev) => {
      const input = ev.target
      if (input.classList.contains('radio-card__input')) {
        const selected = document.querySelector(`.radio-card--selected [name="${input.getAttribute('name')}"]`)
        if (selected) {
          selected.offsetParent.classList.remove('radio-card--selected')
          selected.nextElementSibling.innerHTML = "Select"
          if (selected !== ev.target) {
            ev.target.offsetParent.classList.add('radio-card--selected')
            ev.target.nextElementSibling.innerHTML = "Selected &check;"
          }
        } else {
          ev.target.offsetParent.classList.add('radio-card--selected')
          ev.target.nextElementSibling.innerHTML = "Selected &check;"
        }
      }
    })
  }

  stepListener() {
    const step1 = document.querySelector('[data-step="1"]')
    const step2 = document.querySelector('[data-step="2"]')
    document.addEventListener('click', (ev) => {
      if (ev.target.id === "job-dialog-next") {
        step1.classList.remove('active')
        step2.classList.add('active')
        document.querySelector('.jobs-dialog').remove()
      }
      if (ev.target.id === "job-dialog-back") {
        step1.classList.add('active')
        step2.classList.remove('active')
      }
    })
  }

  appendFormObject(formData) {
    formData.append('title', document.querySelector('#job-title').value)
    formData.append('location', document.querySelector('#job-location').value)
    formData.append('closing_date', document.querySelector('#job-closing').value)
    formData.append('job_type', document.querySelector('#job-type').value)
    formData.append('application_url', document.querySelector('#job-url').value)
    formData.append('employer', document.querySelector('#job-employer').value)
    // Todo: Legge til customer-felter fra step-2
    // 'logo': document.querySelector('#job-logo-preview').src,
    return formData
  }



}

export default Jobs