class SiteMenu {

  constructor() {
    this.menu = document.querySelector('.site-menu__list')
    this.listen()
  }

  listen() {
    this.menu.addEventListener('click', (ev) => {
      // Listen for header
      if (ev.target.classList.contains('site-menu__title')) {
        ev.target.firstElementChild.classList.add('open')
      }
      // Listen for back
      if (ev.target.classList.contains('site-menu__back')) {
        ev.target.closest('.open').classList.remove('open')
      }
    })
  }

}

export default SiteMenu