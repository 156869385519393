class FeaturedLinksScroller {

  constructor() {
    this.block = document.querySelector('.featured-links')
    if (this.block) {
      this.listen()
    }
  }

  listen() {
    const blocks = document.querySelectorAll('.featured-links')
    blocks.forEach(block => {
      this.itemWidth = this.block.querySelector('.featured-links__item').scrollWidth
      const list = block.querySelector('.featured-links__list')
      list.scrollLeft = 0
      let flipped = false
      let currentPos = 0
      this.setProgressBar(block, currentPos, list.scrollWidth - list.clientWidth)

      block.addEventListener('click', ev => {
        let scrollLeftMax = list.scrollWidth - list.clientWidth
        if (ev.target.classList.contains('featured-links__button')) {
          if (flipped) {
            currentPos = list.scrollLeft - this.itemWidth
          } else {
            currentPos = list.scrollLeft + this.itemWidth
          }
          list.scroll(currentPos, 0)
          if (currentPos >= scrollLeftMax) {
            ev.target.classList.add('featured-links__button--flip')
            flipped = true
          }
          if (currentPos <= 0) {
            ev.target.classList.remove('featured-links__button--flip')
            flipped = false
          }
          this.setProgressBar(block, currentPos, scrollLeftMax)
        }
      })

      block.querySelector('.featured-links__list').addEventListener('scroll', ev => {
        if (this.isTouchDevice()) {
          let scrollLeftMax = list.scrollWidth - list.clientWidth
          this.setProgressBar(block, list.scrollLeft, scrollLeftMax)
        }
      })
    })
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

  setProgressBar(block, current, max) {
    let bar = block.querySelector('.featured-links__progress')
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    let percentage = clamp((current / max) * 100, 5, 100)
    if (!percentage) percentage = 5
    bar.style.setProperty('--progress', percentage + "%")
    // bar.style.width = percentage + "%"
  }

}

export default FeaturedLinksScroller