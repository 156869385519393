class Newswire {
  constructor() {
    this.button = document.querySelector("#newswire-more")
    this.language = window.location.pathname.split("/")[1]
    if (this.language === 'nordic') {
      this.locale = "no"
    } else if (this.language.length > 2 || this.language === "") {
      this.language = "en-GB"
      this.locale = "en-GB"
    } else {
      this.locale = this.language
    }
    this.loading = false
    this.loader = document.querySelector('.newswire__loader')
    this.container = document.querySelector('.newswire__list')
    if (this.button) {
      this.listen()
      this.checkForNewArticles()
    }
  }

  listen() {
    if ((this.language !== "nordic" && this.language.length > 2) || !this.language) {
      this.language = "en"
    }
    // For mobile
    this.button.addEventListener('click', () => {
      this.getArticles()
      this.container.parentElement.style.maxHeight = this.container.scrollHeight + "px"
      this.container.style.maxHeight = this.container.scrollHeight + "px"
    })

  // lazy load on larger screen sizes
    if (window.innerWidth >= 1024) {
      this.container.addEventListener('scroll', (ev) => {
        if (this.container.getAttribute('style')) this.container.removeAttribute('style')
        const scrollTopMax = this.container.scrollHeight - this.container.clientHeight
        if (!this.loading && this.container.scrollTop >= scrollTopMax) {
          this.getArticles()
        }
      })
    } else {
      this.loading = false;
      document.querySelector('.newswire__loader').classList.add('newswire__loader--hidden')
    }
    // Safety net against Mr. Resize
    addEventListener('resize', (ev) => {
      if (window.innerWidth >= 1024 && this.container.getAttribute('style')) {
        this.container.removeAttribute('style')
      }
    })
  }

  getArticles() {
    this.loading = true
    this.loader.classList.remove('newswire__loader--hidden')
    let cursorEl = document.querySelector('.newswire [data-cursor]')
    let cursor = cursorEl.getAttribute('data-cursor')
    fetch(`/api/news?language=${this.language}&cursor=${cursor}&newswire=true`).then(res => {
      res.json().then(data => {
        cursorEl.setAttribute('data-cursor', data.endCursor)
        const template = document.querySelector('#newswire-item')
        let currentDateContainer = this.container.querySelector('.newswire__articles:last-of-type')
        let currentDateTitle = ""
        let currentDate = ""
        if (currentDateContainer.previousElementSibling) {
          currentDateTitle = currentDateContainer.previousElementSibling
          currentDate = new Date(currentDateTitle.getAttribute('data-time').split("T")[0]).getTime()
        } else {
          currentDate = new Date().setHours(0,0,0,0)
        }
        data.items.forEach(item => {
          if (!currentDate) {
            let today = new Date()
            currentDate = today.setHours(0,0,0,0)
          }
          if (typeof currentDate === 'string') {
            currentDate = new Date(currentDate).setHours(0,0,0,0)
          }
          if (currentDate > new Date(item.publicationDate)) {
            currentDateContainer.insertAdjacentHTML('beforeend', '</ul>')
            const weekAgo = new Date()
            weekAgo.setDate(weekAgo.getDate() - 7)
            let dateHeader = ""

            if (new Date(item.publicationDate.split("T")[0]) > weekAgo) {
              dateHeader = new Intl.DateTimeFormat(this.locale, {weekday: 'long'}).format(new Date(item.publicationDate))
            } else {
              dateHeader = new Intl.DateTimeFormat(this.locale, {dateStyle: 'short'}).format(new Date(item.publicationDate))
            }
            currentDateContainer.insertAdjacentHTML('afterend',
              `<h3 class="newswire__heading" data-time="${item.publicationDate}">${dateHeader}</h3>
              <ul class="newswire__articles">`)
            currentDateContainer = this.container.querySelector('.newswire__articles:last-of-type')
            currentDate = item.publicationDate
          }
          const clone = template.content.cloneNode(true)
          let el = clone.querySelector('.newswire__item')
          // el.querySelector('.newswire__item-link').href = `${this.language}/news/${item.id}/${item.slug}`
          el.querySelector('.newswire__item-link').href = `https://app.montelnews.com/News/newsframe.aspx?id=${item.montelId}`


          el.querySelector('.newswire__item-title').innerHTML = item.title
          el.querySelector('.newswire__item-time').innerHTML = Intl.DateTimeFormat(this.locale, {hour: '2-digit', minute: '2-digit'}).format(new Date(item.publicationDate))
          el.querySelector('.newswire__item-time').setAttribute('data-time', item.publicationDate)
          currentDateContainer.appendChild(el)
        })
      })
      this.loading = false
    })
  }

  checkForNewArticles() {
    let first = null
    let year = 2023
    let id = 1537441
    setInterval(() => {
      first = document.querySelector(".newswire__item")
      fetch(`/api/news?language=${this.language}&First=1`).then(res => {
        res.json().then(data => {
          const item = data.items[0]

          let first_id = first.getAttribute('article_id')
          let first_time = first.getAttribute('data-time')
          let new_date = item.publicationDate
          if (first && item.montelId !== first_id && first_time && Date.parse(new_date) > Date.parse(first_time)) {
            const template = document.querySelector('#newswire-item')
            const clone = template.content.cloneNode(true)
            let el = clone.querySelector('.newswire__item')
            el.querySelector('.newswire__item-link').href = `https://app.montelnews.com/News/newsframe.aspx?id=${item.montelId}`
            el.querySelector('.newswire__item-title').innerHTML = item.title
            const pubDate = new Date(item.publicationDate).toLocaleTimeString(this.locale, {
              hour: '2-digit',
              minute: '2-digit'
            })
            el.querySelector('.newswire__item-time').innerHTML = pubDate
            el.querySelector('.newswire__item-time').setAttribute('data-time', item.publicationDate)
            el.setAttribute('data-time', item.publicationDate)
            el.style.maxHeight = 0
            el.style.opacity = 0
            document.querySelector(".newswire__articles").insertAdjacentElement('afterbegin', el)
            // const domElement = document.querySelector('.newswire__articles .newswire__item')
            el.style.maxHeight = el.scrollHeight + "px"
            el.style.opacity = 1
          }
        })
      })
    }, 30000)
  }
}

export default Newswire