class VideoPlayer {

  constructor() {
    this.videoPage = document.querySelector('video')
    if (this.videoPage) {
      this.listen()
    }
  }

  listen() {
    addEventListener('click', ev => {
      if (ev.target.classList.contains('video__poster')) {
        const video = ev.target.nextElementSibling
        video.setAttribute('src', video.getAttribute('data-url'))
        video.play()
        video.removeAttribute('style')
        ev.target.previousElementSibling.remove()
        ev.target.remove()
      }
    })
  }

}

export default VideoPlayer