import { v4 as uuidv4 } from 'uuid';

const EventType = {
	BeginRead: 1,
	EndRead: 2
}

class NewsAnalytics {
	static traceId
	static articleMontelId
	static platformName
	static analyticsApiUrl

	configure = (analyticsApiUrl, platformName) => {
		this.platformName = platformName;
		this.analyticsApiUrl = analyticsApiUrl;
	};

	beginRead = (articleMontelId) => {
		if (!this.analyticsApiUrl || !this.platformName) {
			throw new Error('Run NewsAnalytics.configure(analyticsApiUrl, platformName) before using beginRead.');
		}

		this.traceId = this.generateTraceId();
		this.articleMontelId = articleMontelId;

		this.collect(EventType.BeginRead);
	};

	endRead = () => {
		if (!this.traceId || !this.articleMontelId) {
			throw new Error('endRead() cannot be used before beginRead().');
		}

		this.collect(EventType.EndRead);
		this.traceId = "";
	};

	collect = (eventType) => {
		fetch(`${this.analyticsApiUrl}/Analytics/Collect`, {
			headers: { "Content-Type": "application/json; charset=utf-8" },
			method: 'POST',
			body: JSON.stringify({
				traceId: this.traceId,
				articleId: this.articleMontelId,
				eventType: eventType
			}),
			keepalive: true
		});
	}

	generateTraceId = () => {
		return uuidv4();
	};
}

export default NewsAnalytics;
