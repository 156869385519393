class PlayOnHover {

  constructor() {
    this.listen()
  }

  listen() {
    const videos = document.querySelectorAll('.news-item__video')
    if (videos) {
      videos.forEach(video => {
        video.closest('.news-item').addEventListener('mouseenter', (ev) => {
          video.play()
        })
        video.closest('.news-item').addEventListener('mouseleave', () => {
          video.pause()
          video.currentTime = 0
        })
      })
    }
  }

}

export default PlayOnHover