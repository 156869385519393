class Resizing {

  static listener() {

    let alreadyMoved = false
    this.logoHome = document.querySelector('.site-logo')
    this.logo = document.querySelector('#site_logo')
    this.menu = document.querySelector('.action-menu')


    // init mobile-layout, if necessary. Desktop is default
    if (window.innerWidth < 768) {
      this.mobileHeader()
    }

    // Listen for resizing
    window.matchMedia('(min-width: 768px)').addEventListener('change', () => {
      if (window.innerWidth >= 768) {
        this.desktopHeader()
      } else {
        this.mobileHeader()
      }
    })
  }

  static desktopHeader() {
    this.logoHome.append(this.logo)
  }
  static mobileHeader() {
    this.menu.append(this.logo)
  }
}

export default Resizing