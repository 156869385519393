import Close from "./Close"
import SearchWidgetHandler from "./SearchWidget"

class Dropdown {

  static hiddenClass = 'featured-menu__dropdown--hidden'

  constructor() {
    this.buttons = document.querySelectorAll('.featured-menu__drop-button')
    if (this.buttons.length > 0) {
      this.listen()
    }
  }

  listen() {
    const menu = document.querySelector('.featured-menu')
    document.addEventListener('click', ev => {
      if (ev.target.classList.contains('featured-menu__drop-button')) {
        let dropdown = ev.target.nextElementSibling
        if (dropdown.classList.contains('featured-menu__dropdown--hidden')) {
          Dropdown.close()
          dropdown.classList.remove('featured-menu__dropdown--hidden')
        } else {
          dropdown.classList.add('featured-menu__dropdown--hidden')
        }
      } else {
        Dropdown.close()
      }

    })
  }

  open(dropdown) {
    dropdown.classList.remove(Dropdown.hiddenClass)
    Close.modals('dropdown')
  }

  static close() {
    const openDropdown = document.querySelector(`.featured-menu__dropdown:not(.${Dropdown.hiddenClass})`)
    if (openDropdown) {
      openDropdown.classList.add(Dropdown.hiddenClass)
    }
  }

}

export default Dropdown