import Close from "./Close"

class SearchWidget {

  static widget = document.querySelector('.search')
  constructor() {
    this.widget = SearchWidget.widget
    if (this.widget) {
      this.firstOpen = true
      this.listenForWidgetToggle()
      this.listenForExit()
    }
  }

  listenForWidgetToggle() {
    const toggleButton = document.querySelector('#search-toggle')
    toggleButton.addEventListener('click', () => {
      this.toggleWidget()
    })
  }

  listenForExit() {
    const exitButton = this.widget.querySelector('#search-exit')
    exitButton.addEventListener('click', () => {
      SearchWidget.close()
    })

    const overlay = this.widget.querySelector('.search__overlay')
    overlay.addEventListener('click', () => {
      SearchWidget.close()
    })

    document.addEventListener('keydown', (ev) => {
      if (ev.key == "Escape") {
        SearchWidget.close()
      }
    })
  }

  toggleWidget() {
    if (this.widget.style.maxHeight == "0px") {
      this.open()
    } else {
      SearchWidget.close()
    }
  }

  open() {
    if (SearchWidget.widget) {
      SearchWidget.widget.classList.add('search--show')
      SearchWidget.widget.style.maxHeight = SearchWidget.widget.scrollHeight + "px"
      Close.modals('search')
      document.body.style.overflowY = "hidden"
      if (this.firstOpen) {
        document.querySelector('#search-input').focus()
        this.firstOpen = false
      }
    }
  }

  static close() {
    if (SearchWidget.widget) {
      SearchWidget.widget.classList.remove('search--show')
      SearchWidget.widget.style.maxHeight = "0px"
      document.body.removeAttribute("style")
      let resultBox = document.querySelector('#search-input.open')
      if (resultBox) {
        resultBox.classList.remove('open')
      }
    }
  }

}

export default SearchWidget


// class SearchListener {
//   static clearButton =
//     constructor() {
//   this.clearer()
//   this.closer()
// }

// clearer() {
//   let button = document.querySelector('#search__clear')
//   button.addEventListener('click', (ev) => {
//     ev.preventDefault()
//     button.previousElementSibling.value = ""
//   })
// }

// closer() {
//   const parent = document.querySelector('.search__container');
//   parent.addEventListener('click', ev => {
//     if (ev.target.classList.contains('search__overlay')) {
//       this.close()
//     }
//   })
// }

//   static close() {
//   document.querySelector('')
// }

// }

// export default SearchListener