class PurchasableArticles {

  constructor() {
    const isPaidArticle = document.querySelector('[data-access="PURCHASABLE"]')
    if (isPaidArticle) {
      this.addLink()
    }
  }

  addLink() {
    const id = document.querySelector('.article').getAttribute('data-id')
    document.querySelector('.article__body').insertAdjacentHTML('afterbegin',
    `<a target="_blank" href="https://app.montelnews.com/News/newsframe.aspx?id=${id}">Read the full story on Montel Online</a>`)
  }

}

export default PurchasableArticles