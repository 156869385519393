class PodcastPlayer {

  constructor() {
    this.widget = document.querySelector(".podcast-player");
    if (this.widget) {
      this.playlist = document.querySelector('.podcast-page__playlist')
      this.audio = this.widget.querySelector('audio')
      this.playing = false
      this.muted = false
      this.lastVolume = 100
      this.first = true
      this.trackElapsedTime()
      this.tracking = false
      this.listen()
      this.playlistHandler()
      this.seasonHandler()
    }
  }

  stop() {
    this.widget.querySelector('#play-icon').style.display = "block";
    this.widget.querySelector('#pause-icon').style.display = "none";
    this.playing = false
    this.audio.pause()
  }

  play() {
    this.widget.querySelector('#play-icon').style.display = "none";
    this.widget.querySelector('#pause-icon').style.display = "block";
    this.playing = true
    this.audio.play()
    this.trackElapsedTime()
  }

  listen() {
    // Play button
    let toggleButton = document.querySelector('#play-toggle')
    toggleButton.addEventListener('click', () => {
      if (this.playing) {
        this.stop()
      } else {
        this.play()
      }
    })

    // Timeline
    this.widget.querySelector('#podcast-bar').addEventListener('change', (ev) => {
      this.audio.currentTime = Number(ev.target.value)
    })

    // Toggle audio volume
    const audioEnabled = this.widget.querySelector('#podcast-volume-on')
    const audioDisabled = this.widget.querySelector('#podcast-volume-off')

    this.widget.querySelector('#podcast-volume-toggle').addEventListener('click', () => {
      if (this.muted) {
        this.audio.volume = this.lastVolume / 100
        audioDisabled.style.display = "none";
        audioEnabled.style.display = "block";
        this.widget.querySelector('#podcast-volume-range').value = this.lastVolume;
      } else {
        audioDisabled.style.display = "block";
        audioEnabled.style.display = "none";
        this.audio.volume = 0
        this.widget.querySelector('#podcast-volume-range').value = 0;
      }
      this.muted = !this.muted
    })

    // Volume slider
    this.widget.querySelector('#podcast-volume-range').addEventListener('change', (ev) => {
      if (ev.target.value == 0) {
        this.audio.volume = 0;
        audioEnabled.style.display = "none";
        audioDisabled.style.display = "block";
        this.muted = true
      } else {
        this.audio.volume = ev.target.value / 100
        audioEnabled.style.display = "block";
        audioDisabled.style.display = "none";
        this.muted = false
      }
      this.lastVolume = ev.target.value
    })
  }

  trackElapsedTime() {
    /* Textual representation */
    const timer = this.widget.querySelector('#podcast-elapsed')
    const bar = this.widget.querySelector('#podcast-bar')
    if (!this.tracking) {
      let tracking = setInterval(() => {
        this.tracking = true
        if (this.audio.ended) {
          this.widget.querySelector('#play-icon').style.display = "block";
          this.widget.querySelector('#pause-icon').style.display = "none";
          this.audio.currentTime = 0
          timer.innerHTML = "0:00"
          this.tracking = false
          this.playing = false
          clearInterval(tracking)
        }
        let minutes = Math.floor(this.audio.currentTime / 60)
        let seconds = Math.floor(this.audio.currentTime - (minutes * 60))
        if (seconds < 10) {
          seconds = ('0' + seconds).slice(-2);
        }
        timer.innerHTML = minutes + ":" + seconds

        /* Visual representation */
        let normalized = this.audio.currentTime / this.audio.duration * 100
        bar.style.backgroundSize = `${normalized}% 100%`
        bar.value = Math.floor(this.audio.currentTime)

      }, 1000)
    }

  }

  playlistHandler() {
    this.playlist.addEventListener('click', (ev) => {
      if (ev.target.classList.contains('podcast-page__playlist')) return
      this.stop()
      let active = this.playlist.querySelector('.podcast--active')
      if (active) {
        active.classList.remove('podcast--active')
      }
      ev.target.classList.add('podcast--active')
      let player = document.querySelector('.podcast__player')

      // change data for player
      let seasonNum = ev.target.getAttribute('data-season')
      let episodeNum = ev.target.getAttribute('data-episode')

      fetch(`/api/podcast/season/${seasonNum}/episode/${episodeNum}`).then(res => res.json().then(podcast => {
        const date = new Date(podcast.published_at).toLocaleString('no-nb', { day: '2-digit', month: '2-digit', year: 'numeric' })
        const duration = Math.floor(podcast.duration / 60) + ":" + (podcast.duration % 60 < 10 ? '0' : '') + (podcast.duration % 60)
        player.querySelector('.podcast__season').innerHTML = String(podcast.season_number).padStart(2, '0')
        player.querySelector('.podcast__episode').innerHTML = String(podcast.episode_number).padStart(2, '0')
        player.querySelector('.podcast__date').innerHTML = date
        player.querySelector('.podcast__title').innerHTML = podcast.title
        player.querySelector('.podcast__duration').innerHTML = duration
        player.querySelector('#podcast-bar').setAttribute('max', podcast.duration)
        player.querySelector('.podcast__source').setAttribute('src', podcast.audio_url)
        player.querySelector('.podcast__body').innerHTML = podcast.description
        this.play()
        if (window.innerWidth < 769) {
          player.scrollIntoView()
        }
      }))
    })
  }

  seasonHandler() {
    let container = document.querySelector('.podcast-page__seasons')
    let playlist = document.querySelector('.podcast-page__playlist')
    let template = document.querySelector('#podcast-template')
    let loader = document.querySelector('#playlist-loader');
    container.addEventListener('click', (ev) => {
      if (!ev.target.value) return
      // get template, fill with
      playlist.innerHTML = ""
      loader.classList.add('active')
      let active = container.querySelector('.button--active')
      if (active) {
        active.classList.remove('button--active')
      }
      ev.target.classList.add('button--active')
      fetch(`/api/podcast/season/${ev.target.value}`)
      .then(res => res.json().then(episodes => {
        episodes.forEach(episode => {
          const clone = template.content.cloneNode(true)
          const date = new Date(episode.published_at).toLocaleString('no-nb', { day: '2-digit', month: '2-digit', year: 'numeric'})
          clone.querySelector('.podcast').setAttribute('data-episode', episode.episode_number)
          clone.querySelector('.podcast').setAttribute('data-season', episode.season_number)
          clone.querySelector('.podcast__season').textContent = String(episode.season_number).padStart(2, '0')
          clone.querySelector('.podcast__episode').textContent = String(episode.episode_number).padStart(2, '0')
          clone.querySelector('.podcast__date').textContent = date
          clone.querySelector('.podcast__title').textContent = episode.title
          playlist.append(clone)
        })
        loader.classList.remove('active')
      }))
    })
  }
}

export default PodcastPlayer