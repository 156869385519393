import Close from "./Close"

class Navigation {

  static menu = document.querySelector('#nav')

  constructor() {
    this.menu = Navigation.menu
    this.headerHeight = document.querySelector('.header').clientHeight
    if (this.menu) {
      this.button = document.querySelector('#burger')
      this.listen()
    }
  }

  listen() {
    this.button.addEventListener('click', () => {
      if (this.menu.style.maxHeight == "0px") {
        this.open()
      } else {
        Navigation.close()
      }
    })
    let overlay = document.querySelector('.nav__overlay')
    overlay.addEventListener('click', () => {
      Navigation.close()
    })
    document.addEventListener('keyup', (ev) => {
      if (ev.key === "Escape") {
        Navigation.close()
      }
    })
  }

  open() {
    if (window.innerWidth >= 768) {
      document.body.classList.add('no-overflow')
      this.menu.style.maxHeight = this.menu.scrollHeight + "px"
      Close.modals('menu')
    } else {
      document.body.classList.add('no-overflow')
      if (this.menu.hasAttribute('style')) {
        this.menu.style.maxHeight = (window.innerHeight - document.querySelector('.header').clientHeight) + "px"
      }
      Close.modals('menu')
    }
    document.querySelector('.site-menu__button').classList.add('open')
  }

  static close() {
    document.body.classList.remove('no-overflow')
    Navigation.menu.style.maxHeight = "0px"
    document.querySelector('.site-menu__button').classList.remove('open')
    let openSubmenu = Navigation.menu.querySelector('.open')
    if (openSubmenu) openSubmenu.classList.remove('open')

  }

}

export default Navigation;