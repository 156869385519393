class ArticleLinks {

  constructor() {
    this.base_url = '/news/'
    if (document.querySelector('main.article'))
      this.inline()
      this.card()
  }

  inline() {
    const links = document.querySelectorAll('[href^="navto://"]')
    links.forEach(link => {
      const id = link.href.split('/').slice(-1).toString()
      fetch(`/api/articles/${id}/meta`)
      .then(res => {
        if (res.status === 200) {
          res.json()
          .then(data => {
            link.title = data.title
            link.href = link.href.replace('navto://', '/' + this.getLanguage() + '/news/')
            link.href += `/${data.slug}`
          })
        } else {
          link.outerHTML = link.innerHTML
        }
      })
    })
  }

  getLanguage() {
    return window.location.pathname.split('/')[1]
  }

  card() {
    const placeholders = document.querySelectorAll('[purpleid]')
    const template = document.querySelector("#article-card")
    placeholders.forEach(placeholder => {
      const clone = template.content.cloneNode(true)
      const card = clone.querySelector('article')
      const id = placeholder.getAttribute('purpleid')

      card.setAttribute('data-id', id)
      placeholder.insertAdjacentElement('afterend', card)
      this.setArticleContent(id, card)
      placeholder.remove()
    })
  }

  setArticleContent(id, card) {
    fetch(`/api/news/${id}/meta`).then(res => {
       if (res.ok) {
        res.json().then(data => {
          if (data != 404) {
            const link = "/" + this.getLanguage() + this.base_url + id + "/" + data.slug
            card.querySelector('.article-card__link').href = link
            card.querySelector('.article-card__link').innerHTML = data.title
            card.querySelector('.article-card__image-link').href = link
            card.querySelector('.article-card__image').src = data.featured_image.url
            card.querySelector('.article-card__image').alt = data.featured_image.altText
            if (data.date) {
              card.querySelector('.article-card__published').innerHTML = data.date
            } else {
              card.querySelector('.article-card__published').remove()
            }
            card.querySelector('.article-card__read-counter').innerHTML = data.read_time
            const container = card.querySelector('.article-card__categories')
            let length = data.topics.length
            if (length === 0) container.remove()
            for (let i = 0; i < length; i++) {
              if (i !== 0) container.insertAdjacentHTML("beforeend", " | ")
              let anchor = document.createElement('a')
              anchor.href = "/topics/" + data.topics[i].id
              anchor.innerHTML = data.topics[i].name
              container.appendChild(anchor)
            }
          } else {
            // If article is not found in API - remove teaser from DOM
            card.remove()
          }
        })
      } else {
        card.remove()
      }



    })
  }

}

export default ArticleLinks