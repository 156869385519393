class ShareLinks {
  constructor() {
    this.init()
  }

  init() {
    const current_url = encodeURIComponent(window.location.href)
    let share_url = ""
    let open_window = true
    document.querySelector(".article__sharing").addEventListener("click", (ev) => {
      console.log(ev.target)
        if (ev.target.classList.contains("sharing")) {
          switch (ev.target.dataset.channel) {
            case 'facebook':
              share_url = `https://www.facebook.com/sharer/sharer.php?u=${current_url}`
              open_window = true
              break
            case 'x':
              share_url = `https://twitter.com/intent/tweet?url=${current_url}`;
              open_window = true
              break
            case 'linkedin':
              share_url = `https://www.linkedin.com/shareArticle?mini=true&url=${current_url}`
              open_window = true
              break
            case 'clipboard':
              open_window = false
              this.executeCopy(window.location)
              let el = document.querySelector('.article__share-url')
              el.classList.add('article__share-url--hiding')
              setTimeout(() => {el.classList.remove('article__share-url--hiding')}, 5000)
              break
            default:
              console.log("No channel set");
              console.log(ev.target.getAttribute('data-channel'))
              break;
            }
          if (open_window) {
            window.open(share_url, "sharing", "toolbar=0,status=0,top=100,width=626,height=436")
          }
        }
      });
  }
  executeCopy(input) {
    let copyhelper = document.createElement("input");
    copyhelper.className = 'copyhelper'
    document.body.appendChild(copyhelper);
    copyhelper.value = input;
    copyhelper.select();
    document.execCommand("copy");
    document.body.removeChild(copyhelper);
  }
}

export default ShareLinks;
